<template>
  <fetch-bookings />
</template>

<script>
import FetchBookings from '@/components/pages/bookings/FetchBookings.vue';

export default {
  components: { FetchBookings },
};
</script>
